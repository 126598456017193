<template>
  <div class="fd-flex fd-items-center fd-justify-start">
    <div
      v-for="(option, index) in options"
      :key="index"
      class="fd-flex fd-items-center"
      :class="[index ? 'fd-ml-6' : '']"
    >
      <span
        class="fd-w-9 fd-h-9 fd-flex fd-items-center fd-justify-center fd-rounded-full"
        :class="[getSteps.includes(index) ? 'fd-bg-theme-4' : 'fd-bg-theme-12']"
      >
        <SvgLoader v-if="getSteps.includes(index)" :name="'checkIcon'" />
      </span>
      <span
        class="fd-block fd-ml-2 fd-text-lg"
        :class="[getSteps.includes(index) ? 'fd-font-semibold fd-text-theme-13' :'fd-font-normal fd-text-theme-10']"
      >
        {{ option.title }}
      </span>
      <span
        v-if="(options.length - 1) !== index"
        class="fd-w-11 fd-h-1 fd-ml-2 fd-border-b-2"
        :class="[getSteps.includes(index) ? 'fd-border-solid fd-border-theme-14' : 'fd-border-dashed fd-border-theme-10']"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Stepper",
  data() {
    return {
      steps: []
    }
  },
  props: {
    options: {
      type: Array,
      required: true
    }
  },
  created() {
    this.activeSteps()
  },
  watch: {
    '$route': {
      handler() {
        this.activeSteps()
      }
    }
  },
  computed: {
    getSteps() {
      return this.steps
    }
  },
  methods: {
    activeSteps() {
      let optionIndex = 0
      this.options.forEach((item, index) => {
        if (item.routeName === this.$route.name) {
          optionIndex = index
        }
      })
      this.steps = []
      if (!optionIndex) {
        this.steps.push(0)
        return;
      }
      for (let i = 0; i <= optionIndex; i++) {
        this.steps.push(i)
      }
    }
  }
}
</script>

<style scoped>

</style>