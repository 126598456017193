<template>
  <div class="fd-w-full fd-bg-white" :class="[$route.name !== 'projects.templates' ? '' : 'fd-px-4 fd-py-6']" style="min-height: 100%">
    <div class="fd-w-full" v-if="$route.name !== 'projects.templates'">
      <div class="fd-w-full fd-py-4 fd-flex fd-justify-center" style="background-color: #F9FBFA">
        <Stepper  :options="stepper"/>
      </div>
      <router-view></router-view>
    </div>

    <Table
      v-else
      :name="name"
      :total="total"
      :fields="fields"
      :items="phases"
      :hasFilter="false"
      :hasSearch="true"
      :perPage="10"
      :isBusy="isBusy"
      :hasImageColumn="true"
      @changedPage="setPage"
      @setSearch="setSearch"
      @showRemoveComponent="showRemoveComponent"
      @showEditComponent="editPhase"
    />
    <ConfirmDialogue @remove="removeTemplate" />
  </div>
</template>

<script>
import Table from "@/views/components/global/Table";
import {mapGetters} from "vuex";
import {bus} from "@/main";
import Stepper from "@/views/components/global/Stepper";
import ConfirmDialogue from "@/views/components/global/ConfirmDialogue";
import toast from "@/mixins/toast";

export default {
  name: "Template",
  components: {ConfirmDialogue, Stepper, Table},
  mixins: [toast],
  data() {
    return {
      loading: false,
      btnTitle: null,
      search: '',
      itemId: null
    }
  },
  created() {
    if (this.$route.meta.button) {
      this.btnTitle = this.$route.meta.button.btnTitle
      bus.$on(this.btnTitle, () => {
        this.createTemplate()
      })
    }

    let data = {
      id: this.$route.params.id
    }
    this.getData(data)
  },

  watch: {
    '$route.name': {
      handler() {
        if (this.$route.name !== 'projects.templates') return
        let data = {
          id: this.$route.params.id
        }
        this.getData(data)
      },
      deep: true
    },
    search: {
      handler(newVal) {
        let data = {
          id: this.$route.params.id,
        }
        if (newVal) {
          data.search = newVal
        }
        this.getData(data)
      }
    }
  },
  computed: {
    ...mapGetters({
      phases: 'project/templates'
    }),
    fields() {
      return [
        { key: 'name', label: this.$t('table.name'), sortable: true, sortDirection: 'desc' },
        { key: 'template_type', label: this.$t('table.templateType') },
        { key: 'property_type', label: this.$t('table.propertyType') },
        { key: 'living_room', label: this.$t('table.livingRoom') },
        { key: 'bed_room', label: this.$t('table.bedRoom') },
        { key: 'bath_room', label: this.$t('table.bathRoom') },
        { key: 'net', label: this.$t('table.net') },
        { key: 'grass', label: this.$t('table.gross') },
        { key: 'orientation', label: this.$t('table.orientation') },
        { key: 'actions', label: this.$t('table.actions'), class: 'text-right' }
      ]
    },
    name() {
      return this.$t('secondSidebar.Templates')
    },
    total() {
      return this.phases.length
    },
    isBusy() {
      return this.loading
    },
    stepper() {
      return [
        {
          title: 'Main Info',
          routeName: 'projects.templates.mainInfo'
        },
        {
          title: 'Media',
          routeName: 'projects.templates.media'
        },
        {
          title: 'Description',
          routeName: 'projects.templates.description'
        },
        {
          title: 'Features',
          routeName: 'projects.templates.features'
        }
      ]
    }
  },
  methods: {
    getData(data) {
      this.loading = true
      this.$store.dispatch('project/getTemplates', data).finally(() => {
        this.loading = false
      })
    },
    setPage(number) {
      let data = {
        id: this.$route.params.id,
        number
      }
      if (this.search) {
        data.search = this.search
      }
      this.getData(data)
    },
    setSearch(search) {
      this.search = search
    },
    createTemplate() {
      return this.$router.push({ name: 'projects.templates.mainInfo' })
    },
    removeTemplate() {
      this.$store.dispatch('project/deleteTemplate', this.itemId).then(response => {
        this.makeToast(response.data.message)
        let data = {
          id: this.$route.params.id,
        }
        if (this.search) {
          data.search = this.search
        }
        this.getData(data)
      })
        .finally(() => {
          this.itemId = null
          this.toggleConfirmDialogue()
        })
    },
    showRemoveComponent(id) {
      this.itemId = id
      this.toggleConfirmDialogue()
    },
    toggleConfirmDialogue() {
      this.$root.$emit("bv::toggle::modal", "confirmDialogue");
    },
    editPhase(id) {
      return this.$router.push({
        name: 'projects.templates.mainInfo',
        params: {
          id: this.$route.params.id,
          templateId: id
        }
      })
    }
  },
  beforeDestroy() {
    bus.$off(this.btnTitle)
  }
}
</script>

<style scoped>

</style>