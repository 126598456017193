<template>
  <div>
    <h2 class="fd-font-semibold fd-text-lg fd-text-theme-2 fd--mt-2 fd-mb-5">{{ $t('global.tableView') }}</h2>
    <div class="fd-w-full fd-bg-white fd-rounded-lg fd-px-4 fd-py-6" style="min-height: 500px;">
      <Table
        :name="name"
        :total="total"
        :fields="fields"
        :items="leadDetails"
        :hasSearch="true"
        :showList="false"
        :hasRemoveIcon="false"
        :hasReserveIcon="true"
        :hasViewIcon="false"
        :hasSoldIcon="true"
        :perPage="10"
        :isBusy="isBusy"
        @changedPage="setPage"
        @setSearch="setSearch"
        @showEditComponent="editAppointment"
        @showSoldComponent="sold"
        @showReserveComponent="reserve"
      >
        <template #referrer="row">
          <div class="fd-flex fd-items-center">
            <img style="width: 30px; height: 30px; object-fit: cover; border-radius: 6px;" :src="row.row.item.referrer.thumbnail" alt="">
            <span class="fd-block fd-ml-2">{{ row.row.item.referrer.agent.first_name }}</span>
          </div>
        </template>
      </Table>
      <SellSideBar v-if="leadId" :leadId="leadId" @setAsSold="setAsSold" />
      <ReserveSideBar v-if="leadId" :leadId="leadId" @setAsReserve="setAsReserve" />
    </div>
  </div>
</template>

<script>
import Table from "@/views/components/global/Table";
import SellSideBar from "@/views/components/leadDetails/SellSideBar";
import ReserveSideBar from "@/views/components/leadDetails/ReserveSideBar";
import Template from "@/views/pages/projectManagement/projects/Template";
export default {
  name: "LeadDetails",
  components: {Template, ReserveSideBar, SellSideBar, Table},
  data() {
    return {
      loading: false,
      leadId: null
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
    this.$store.dispatch('leadDetails/getReserveOperationFilters')
    this.$store.dispatch('leadDetails/getSellOperationFilters')
    this.getData({})
  },
  computed: {
    leadDetails() {
      return this.$store.getters["leadDetails/lead"]
    },
    fields() {
      return [
        {
          key: 'lead',
          label: this.$t('table.lead'),
          formatter: (value, key, data) => {
            return data.lead.first_name + ' ' + data.lead.last_name
          },
          thStyle: { minWidth: '140px !important' }
          },
        {
          key: 'referrer',
          label: this.$t('table.rsAgentMember'),
          thStyle: { minWidth: '170px !important' },
          showCount: true
        },
        { key: 'project.name', label: this.$t('table.project'), thStyle: { minWidth: '140px !important' } },
        { key: 'lead.template', label: this.$t('table.template'), hasBackground: true, thStyle: { minWidth: '100px !important' } },
        { key: 'status', label: this.$t('table.status'), thStyle: { minWidth: '70px !important' } },
        {
          key: 'created_at',
          label: this.$t('table.registerDate'),
          formatter: (value) => {
            return value.split('T')[0]
          },
          thStyle: { minWidth: '70px !important' }
        },
        { key: 'actions', label: this.$t('table.actions'), class: 'text-right', thStyle: { minWidth: '70px !important' } }
      ]
    },
    name() {
      return this.$t('global.lead')
    },
    total() {
      return this.$store.getters["leadDetails/leadTotal"]
    },
    isBusy() {
      return this.loading
    }
  },
  methods: {
    getData(data) {
      this.loading = true
      this.$store.dispatch('leadDetails/getLead', data).finally(() => {
        this.loading = false
      })
    },
    setPage(number) {
      let data = {
        number
      }
      if (this.search) {
        data.search = this.search
      }
      this.getData(data)
    },
    setSearch(search) {
      this.search = search
    },
    sold(item) {
      this.leadId = (item &&'lead' in item) ? item.lead.id : item
      this.$nextTick(() => {
        this.$root.$emit('bv::toggle::collapse', 'sell-sidebar')
      })

    },
    reserve(item) {
      this.leadId = (item && 'lead' in item) ? item.lead.id : item
      this.$nextTick(() => {
        this.$root.$emit('bv::toggle::collapse', 'reserve-sidebar')
      })
    },
    setAsSold(form) {
      this.$store.dispatch('leadDetails/setAsSold', form).then(() => {
        this.sold(null)
        let data = {}
        if (this.search) {
          data.search = this.search
        }
        this.getData(data)
      })
    },
    setAsReserve(form) {
      this.$store.dispatch('leadDetails/setAsReserve', form).then(() => {
        this.reserve(null)
        let data = {}
        if (this.search) {
          data.search = this.search
        }
        this.getData(data)
      })
    },
    editAppointment(id) {
      // this.$store.dispatch('internalAgent/getAgentDetails', id)
      // this.toggleInviteSidebar()
    },
  }
}
</script>

<style scoped>

</style>