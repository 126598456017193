<template>
  <b-sidebar
    id="sell-sidebar"
    no-header
    bg-variant="white"
    shadow right lazy backdrop
    :backdrop-variant="'dark'"
  >
    <template #default="{ hide }">
      <div class="fd-relative fd-flex fd-items-center fd-justify-between fd-p-3" style="background-color: #E9EDEB;">
        <div class="fd-flex fd-items-center">
          <span class="fd-block fd-ml-2">{{ $t('global.sell') }}</span>
        </div>
        <div @click="toggleSidebar" class="fd-cursor-pointer">
          <SvgLoader :name="'close'" />
        </div>
      </div>

      <div class="fd-px-4 fd-mt-2">
        <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
          <label for="phases" class="fd-mb-2">{{ $t('phases.phase') }}</label>
          <multiselect
            id="phases"
            label="name"
            track-by="id"
            select-label=""
            deselect-label=""
            v-model="form.phase"
            :options="sellOperationFilters.phase"
          />
        </div>
        <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
          <label for="complex" class="fd-mb-2">{{ $t('secondSidebar.complex') }}</label>
          <multiselect
            id="complex"
            label="name"
            track-by="id"
            select-label=""
            deselect-label=""
            v-model="form.complex"
            :options="sellOperationFilters.complex"
          />
        </div>
        <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
          <label for="template" class="fd-mb-2">{{ $t('global.template') }}</label>
          <multiselect
            id="template"
            label="name"
            track-by="id"
            select-label=""
            deselect-label=""
            v-model="form.template"
            :options="sellOperationFilters.template"
          />
        </div>
        <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
          <label for="type" class="fd-mb-2">{{ $t('global.type') }}</label>
          <multiselect
            id="type"
            label="name"
            track-by="id"
            select-label=""
            deselect-label=""
            v-model="form.type"
            :options="sellOperationFilters.type"
          />
        </div>
        <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
          <label for="unitNumber" class="fd-mb-2">{{ $t('global.unitNumber') }}</label>
          <multiselect
            id="unitNumber"
            label="name"
            track-by="id"
            select-label=""
            deselect-label=""
            v-model="form.unit_number"
            :options="sellOperationFilters.unit_number"
          />
        </div>
        <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
          <label for="payment" class="fd-mb-2">{{ $t('global.payment') }}</label>
          <multiselect
            id="payment"
            label="name"
            track-by="id"
            select-label=""
            deselect-label=""
            v-model="form.payment"
            :options="sellOperationFilters.payment"
          />
        </div>
        <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
          <label for="currency" class="fd-mb-2">{{ $t('global.currency') }}</label>
          <multiselect
            id="currency"
            label="name"
            track-by="id"
            select-label=""
            deselect-label=""
            v-model="form.currency"
            :options="sellOperationFilters.currency"
          />
        </div>
        <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
          <label for="currency" class="fd-mb-2">{{ $t('global.amount') }}</label>
          <b-form-input
            id="boomark-search-input"
            v-model="form.amount"
            placeholder=""
          />
        </div>
      </div>

      <div class="fd-w-full fd-p-3 fd-bg-white">
        <b-button @click="setAsSold" variant="primary" class="fd-w-full fd-mb-2">{{ $t('global.setAsSold') }}</b-button>
        <b-button @click="toggleSidebar" variant="outline-danger" class="fd-w-full">{{ $t('global.cancel') }}</b-button>
      </div>
    </template>

  </b-sidebar>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-search-select/dist/VueSearchSelect.css'
import { BSidebar, BButton, BFormInput } from 'bootstrap-vue'
import {mapGetters} from "vuex";

export default {
  name: "SellSideBar",
  components: { BSidebar, Multiselect, BButton, BFormInput },
  data() {
    return {
      form: {
        phase: '',
        complex: '',
        template: '',
        type: '',
        unit_number: '',
        payment: '',
        currency: '',
        amount: ''
      }
    }
  },
  props: {
    leadId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      sellOperationFilters: 'leadDetails/sellOperationFilters'
    })
  },
  methods: {
    toggleSidebar() {
      this.$root.$emit('bv::toggle::collapse', 'sell-sidebar')
    },
    setAsSold() {
      let form = {
        lead_id: this.leadId,
        type: this.form.type.id,
        property_id: this.form.template.id,
        payment_type: this.form.payment.id,
        currency: this.form.currency.id,
        amount: this.form.amount
      }
      return this.$emit('setAsSold', form)
    }
  }
}
</script>

<style scoped>

</style>