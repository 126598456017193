import { render, staticRenderFns } from "./SellSideBar.vue?vue&type=template&id=453a55ba&scoped=true&"
import script from "./SellSideBar.vue?vue&type=script&lang=js&"
export * from "./SellSideBar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "453a55ba",
  null
  
)

export default component.exports